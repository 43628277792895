body {
  padding: 0;
  margin: 0;
  overflow: hidden
}

iframe {
  display: flex;
  width: 100vw;
  height: calc(100vh - 50px);
  border: none;
  overflow: scroll;
}

#simpleToast {
  visibility: hidden;
  min-width: 250px;
  /* margin-left: -125px; */
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  font-size: 17px;
}

#simpleToast.show {
  visibility: visible;
  -webkit-animation: fadein 0.65s, fadeout 0.65s 2.5s;
  animation: fadein 0.65s, fadeout 0.65s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 50px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 50px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 50px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 50px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}
@supports (-webkit-touch-callout: none) {
  iframe{
    height: calc(100vh - 80px);
  }
}